<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";
import { formatPrice } from "~/helpers/formatters";
import { getThumbnailUrl } from "~/helpers/mediaHelpers";

const props = defineProps<{
	isLoading: boolean;
	productAssociations: Schemas["CrossSellingElement"][];
	closeDrawer: () => void;
}>();

const { closeDrawer } = props;
const { isLoading, productAssociations } = toRefs(props);

const { addProducts } = useCart();
const { addedToCartObjectIDs } = useAlgoliaSearchInsights();

const selectedProductIds = ref<string[]>([]);
const isUpdatingCart = ref(false);

const products = computed(() => {
	return productAssociations.value?.[0]?.products;
});

const onSubmit = async () => {
	isUpdatingCart.value = true;

	if (selectedProductIds.value.length > 0) {
		await addProducts(selectedProductIds.value.map((id) => ({ referencedId: id, quantity: 1, type: "product" })));
	}

	const selectedProducts = ref<Schemas["Product"][]>([]);

	selectedProductIds.value.forEach((productId) => {
		const p = products.value.find((product) => product.id === productId);
		if (p) selectedProducts.value.push(p);
	});

	if (selectedProducts.value) {
		for (const product of selectedProducts.value) {
			push.success({
				props: {
					isAddToCart: true,
					product: {
						label: product.name,
						thumbUrl: product.cover ? getThumbnailUrl(product.cover.media) : ""
					}
				}
			});
		}
		addedToCartObjectIDs(selectedProductIds.value, selectedProducts.value);
	}

	closeDrawer();

	isUpdatingCart.value = false;
};

watchEffect(() =>
	nextTick(() => {
		if (!isLoading.value && productAssociations.value.length === 0) {
			closeDrawer();
		}
	})
);
</script>

<template>
	<div class="flex flex-col gap-6">
		<span v-if="products">
			<KippieCrossSellProductSelector
				v-model="selectedProductIds"
				v-slot="{ isSelected, onClick }"
				:class="{
					'pointer-events-none opacity-50': isUpdatingCart
				}"
			>
				<KippieCrossSellProduct
					v-for="product in products"
					:key="product.id"
					:checked="isSelected(product.id)"
					@click="onClick(product.id)"
					:name="getTranslatedProperty(product, 'name')"
					:price="formatPrice(product.calculatedPrice.totalPrice, true)"
				>
					<template #image v-if="product.cover?.media">
						<img
							:src="getThumbnailUrl(product.cover.media)"
							width="100"
							quality="100"
							class="px-2 w-full h-full object-contain"
						/>
					</template>
				</KippieCrossSellProduct>
			</KippieCrossSellProductSelector>
		</span>
		<span v-else-if="isLoading">Producten worden geladen...</span>
		<template v-else>Er is iets fout gegaan</template>

		<KippieButton
			:color="selectedProductIds.length > 0 ? 'yellow' : 'white'"
			size="small"
			@click="onSubmit"
			:loading="isUpdatingCart"
		>
			{{
				selectedProductIds.length > 0
					? `${selectedProductIds.length} bijgerechten bestellen`
					: "Doorgaan zonder bijgerecht"
			}}
		</KippieButton>
	</div>
</template>
